import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthConstants, State } from './store/auth.model';
import {
    ActionLoginWithEmailStart,
    ActionLogoutStart,
    ActionRenewAccessToken,
    ActionResetPasswordStart,
    ActionSendEmailVerificationOTPStart,
    ActionSetIsEmailVerified,
    ActionSetRedirectUrl,
    ActionUpdatePasswordStart,
    ActionVerifyEmailOTPStart,
} from './store/auth.actions';
import {
    selectAuthError,
    selectAuthToken,
    selectIsAuthenticating,
    selectIsButtonsDiabled,
    selectIsEmailVerified,
    selectIsLoggedIn,
    selectRedirectUrl,
    selectUserDetails,
} from './store/auth.selectors';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';
import * as dayjs from 'dayjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private readonly store$: Store<State>, private readonly localStorageService: LocalStorageService) {}

    isAuthenticating$ = this.store$.pipe(select(selectIsAuthenticating));
    isLoggedIn$ = this.store$.pipe(select(selectIsLoggedIn));
    isButtonsDisabled$ = this.store$.pipe(select(selectIsButtonsDiabled));
    getAccessToken$ = this.store$.pipe(select(selectAuthToken));
    currentUser$ = this.store$.pipe(select(selectUserDetails));
    getAuthError$ = this.store$.pipe(select(selectAuthError));
    getRedirectUrl$ = this.store$.pipe(select(selectRedirectUrl));
    isEmailVerified$ = this.store$.pipe(select(selectIsEmailVerified));

    loginWithEmail(email: string, password: string): void {
        this.store$.dispatch(ActionLoginWithEmailStart({ email, password }));
    }

    forgotPassword(email: string): void {
        this.store$.dispatch(ActionResetPasswordStart({ email }));
    }

    updatePassword(password: string, token: string): void {
        this.store$.dispatch(ActionUpdatePasswordStart({ password, token }));
    }

    logout(): void {
        this.store$.dispatch(ActionLogoutStart());
    }

    setRedirectUrl(redirectUrl: string): void {
        this.store$.dispatch(ActionSetRedirectUrl({ redirectUrl }));
    }

    sendEmailVerificationOTP(email: string): void {
        this.store$.dispatch(ActionSendEmailVerificationOTPStart({ email }));
    }

    verifyEmailOTP(email: string, otp: number): void {
        this.store$.dispatch(ActionVerifyEmailOTPStart({ email, otp }));
    }

    setIsEmailVerified(isEmailVerified: boolean): void {
        this.store$.dispatch(ActionSetIsEmailVerified({ isEmailVerified }));
    }

    renewAccessToken(): void {
        this.store$.dispatch(ActionRenewAccessToken());
    }

    isAccessTokenValid(): boolean {
        const accessToken = this.localStorageService.getItem(AuthConstants.accessToken);
        const expireAt = this.localStorageService.getItem(AuthConstants.expireAt);
        return !!accessToken && !!expireAt && expireAt > dayjs().format();
    }

}
