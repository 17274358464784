import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../http/http-client.service';
import { City, States } from './store/region.model';

@Injectable({
    providedIn: 'root',
})
export class RegionDataService {
    constructor(private readonly http: HttpClientService) {}

    async fetchStates(): Promise<States[]> {
        try {
            return new Promise((resolve, reject) =>
                this.http.get(environment.api.states).subscribe((response: States[]) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async fetchCities(): Promise<City[]> {
        try {
            return new Promise((resolve, reject) =>
                this.http.get(environment.api.cities).subscribe((response: City[]) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async fetchCitiesByStateId(stateId: number): Promise<City[]> {
        try {
            return new Promise((resolve, reject) =>
                this.http.get(`${environment.api.cities}?stateId=${stateId}`).subscribe((response: City[]) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}
