import { Action, createReducer, on } from '@ngrx/store';
import { ActionSetIsLoading } from './global.actions';
import { GlobalState } from './global.model';

const initialState: GlobalState = {
    isLoading: true,
};

const reducer = createReducer(
    initialState,
    on(ActionSetIsLoading, (state, { isLoading }) => ({ ...state, isLoading }))
);

export function globalReducer(state: GlobalState, action: Action): GlobalState {
    return reducer(state, action);
}
