import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { businessAdminReducer } from './store/business-admin.reducer';
import { StoreModule } from '@ngrx/store';
import { BusinessAdminEffects } from './store/business-admin.effects';
import { EffectsModule } from '@ngrx/effects';
import { BusinessAdminService } from './business-admin.service';
import { BusinessAdminDataService } from './business-admin.data.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('businessAdmins', businessAdminReducer),
        EffectsModule.forFeature([BusinessAdminEffects]),
    ],
    providers: [BusinessAdminService, BusinessAdminDataService],
})
export class BusinessAdminModule {}
