import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';
import { AuthService } from './auth.service';
import { AuthDataService } from './auth.data.service';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule, StoreModule.forFeature('auth', authReducer), EffectsModule.forFeature([AuthEffects])],
    providers: [AuthService, AuthDataService],
})
export class AuthModule {}
