import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../http/http-client.service';
import { BusinessAdmin, BusinessAdminRequest } from './store/business-admin.model';

@Injectable({
    providedIn: 'root',
})
export class BusinessAdminDataService {
    constructor(private readonly http: HttpClientService) {}

    async fetchAllBusinessAdmins(): Promise<BusinessAdmin[]> {
        try {
            return new Promise((resolve, reject) =>
                this.http.get(environment.api.businessAdmin).subscribe((response: BusinessAdmin[]) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async fetchBusinessAdminById(businessAdminId: string): Promise<BusinessAdmin> {
        try {
            return new Promise((resolve, reject) =>
                this.http
                    .get(`${environment.api.businessAdmin}/${businessAdminId}`)
                    .subscribe((response: BusinessAdmin) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async createBusinessAdmin(businessAdmin: BusinessAdminRequest): Promise<BusinessAdmin> {
        try {
            return new Promise((resolve, reject) =>
                this.http
                    .post(environment.api.businessAdmin, businessAdmin)
                    .subscribe((response: BusinessAdmin) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async updateBusinessAdmin(businessAdminId: string, businessAdmin: Partial<BusinessAdmin>): Promise<BusinessAdmin> {
        try {
            return new Promise((resolve, reject) =>
                this.http
                    .put(`${environment.api.businessAdmin}/${businessAdminId}`, businessAdmin)
                    .subscribe((response: BusinessAdmin) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async deleteBusinessAdminById(businessAdminId: string): Promise<BusinessAdmin> {
        try {
            return new Promise((resolve, reject) =>
                this.http
                    .delete(`${environment.api.businessAdmin}/${businessAdminId}`)
                    .subscribe((response: BusinessAdmin) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}
