import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    ActionCreateOrganizationStart,
    ActionDeleteOrganizationByIdStart,
    ActionFetchAllOrganizationsStart,
    ActionFetchOrganizationByIdStart,
    ActionSetIsOrganizationLoading,
    ActionSetOrganizationForm,
    ActionUpdateOrganizationStart,
} from './store/organization.actions';
import { Organization, OrganizationForm, OrganizationRequest, State } from './store/organization.model';
import {
    selectAllOrganizations,
    selectIsOrganizationLoading,
    selectOrganizationForm,
} from './store/organization.selectors';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    constructor(private readonly store$: Store<State>) {}

    isOrganizationLoading$ = this.store$.pipe(select(selectIsOrganizationLoading));
    allOrganizations$ = this.store$.pipe(select(selectAllOrganizations));
    organizationForm$ = this.store$.pipe(select(selectOrganizationForm));

    fetchAllOrganizations(): void {
        this.store$.dispatch(ActionFetchAllOrganizationsStart());
    }

    fetchOrganizationById(organizationId: string): void {
        this.store$.dispatch(ActionFetchOrganizationByIdStart({ organizationId }));
    }

    createOrganization(organization: OrganizationRequest): void {
        this.store$.dispatch(ActionCreateOrganizationStart({ organization }));
    }

    updateOrganization(organizationId: string, organization: Organization): void {
        this.store$.dispatch(ActionUpdateOrganizationStart({ organizationId, organization }));
    }

    deleteOrganization(organizationId: string): void {
        this.store$.dispatch(ActionDeleteOrganizationByIdStart({ organizationId }));
    }

    setIsOrganizationLoading(isOrganizationLoading: boolean): void {
        this.store$.dispatch(ActionSetIsOrganizationLoading({ isOrganizationLoading }));
    }

    setOrganizationForm(organizationForm: OrganizationRequest): void {
        this.store$.dispatch(ActionSetOrganizationForm({ organizationForm }));
    }

}
