import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HttpClientService {
    private accessToken: string;
    private readonly backendUrl = environment.backendUrl;

    constructor(private readonly http: HttpClient, private readonly authService: AuthService, private readonly router: Router) {
        this.authService.getAccessToken$.subscribe((token) => {
            this.accessToken = token;
        });
    }

    private createAuthorizationHeader(): HttpHeaders {
        return new HttpHeaders({
            Authorization: `Bearer ${this.accessToken}`,
        });
    }

    get(url: string): Observable<any> {
        const headers = this.createAuthorizationHeader();
        return this.http
            .get(this.backendUrl + url, {
                headers,
            })
            .pipe(
                catchError((e) => {
                    if (e.status === 401) {
                        this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: this.router.url, renew: true } });
                    }
                    return throwError(e.error);
                })
            );
    }

    post(url: string, data: any): Observable<any> {
        const headers = this.createAuthorizationHeader();
        return this.http
            .post(this.backendUrl + url, data, {
                headers,
            })
            .pipe(
                catchError((e) => {
                    if (e.status === 401) {
                        this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: this.router.url, renew: true } });
                    }
                    return throwError(e.error);
                })
            );
    }

    put(url: string, data: any): Observable<any> {
        const headers = this.createAuthorizationHeader();
        return this.http
            .put(this.backendUrl + url, data, {
                headers,
            })
            .pipe(
                catchError((e) => {
                    if (e.status === 401) {
                        this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: this.router.url, renew: true } });
                    }
                    return throwError(e.error);
                })
            );
    }

    delete(url: string): Observable<any> {
        const headers = this.createAuthorizationHeader();
        return this.http
            .delete(this.backendUrl + url, {
                headers,
            })
            .pipe(
                catchError((e) => {
                    if (e.status === 401) {
                        this.router.navigate(['/auth/login'], { queryParams: { redirectUrl: this.router.url, renew: true } });
                    }
                    return throwError(e.error);
                })
            );
    }
}
