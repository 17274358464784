import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-copyright',
    templateUrl: './copyright.component.html',
    styleUrls: ['./copyright.component.less'],
})
export class CopyrightComponent implements OnInit {
    currentYear = new Date().getFullYear();

    constructor() {}

    ngOnInit(): void {}
}
