import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    ActionCreateBusinessAdminStart,
    ActionDeleteBusinessAdminByIdStart,
    ActionFetchAllBusinessAdminStart,
    ActionFetchBusinessAdminByIdStart,
    ActionSetIsBusinessAdminLoading,
    ActionUpdateBusinessAdminStart,
} from './store/business-admin.actions';
import { BusinessAdminRequest, State } from './store/business-admin.model';
import { selectAllBusinessAdmins, selectIsBusinessAdminLoading } from './store/business-admin.selectors';

@Injectable({
    providedIn: 'root',
})
export class BusinessAdminService {
    constructor(private readonly store$: Store<State>) {}

    isBusinessAdminLoading$ = this.store$.pipe(select(selectIsBusinessAdminLoading));
    allBusinessAdmins$ = this.store$.pipe(select(selectAllBusinessAdmins));

    fetchAllBusinessAdmins(): void {
        this.store$.dispatch(ActionFetchAllBusinessAdminStart());
    }

    fetchAllBusinessAdminById(businessAdminId: string): void {
        this.store$.dispatch(ActionFetchBusinessAdminByIdStart({ businessAdminId }));
    }

    createBusinessAdmin(businessAdmin: BusinessAdminRequest): void {
        this.store$.dispatch(ActionCreateBusinessAdminStart({ businessAdmin }));
    }

    updateBusinessAdmin(businessAdminId: string, businessAdmin: BusinessAdminRequest): void {
        this.store$.dispatch(ActionUpdateBusinessAdminStart({ businessAdminId, businessAdmin }));
    }

    deleteBusinessAdminById(businessAdminId: string): void {
        this.store$.dispatch(ActionDeleteBusinessAdminByIdStart({ businessAdminId }));
    }

    setIsBusinessAdminLoading(isBusinessAdminLoading: boolean): void {
        this.store$.dispatch(ActionSetIsBusinessAdminLoading({ isBusinessAdminLoading }));
    }
}
