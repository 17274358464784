import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { OrganizationDataService } from '../organization.data.service';
import {
    ActionCreateOrganizationFailure,
    ActionCreateOrganizationStart,
    ActionCreateOrganizationSuccess,
    ActionDeleteOrganizationByIdFailure,
    ActionDeleteOrganizationByIdStart,
    ActionDeleteOrganizationByIdSuccess,
    ActionFetchAllOrganizationsFailure,
    ActionFetchAllOrganizationsStart,
    ActionFetchAllOrganizationsSuccess,
    ActionFetchOrganizationByIdFailure,
    ActionFetchOrganizationByIdStart,
    ActionFetchOrganizationByIdSuccess,
    ActionUpdateOrganizationFailure,
    ActionUpdateOrganizationStart,
    ActionUpdateOrganizationSuccess,
} from './organization.actions';
import { Organization } from './organization.model';

@Injectable({
    providedIn: 'root',
})
export class OrganizationEffects {
    constructor(private readonly actions$: Actions<Action>, private readonly organizationDataService: OrganizationDataService) {}

    fetchAllOrganizations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionFetchAllOrganizationsStart),
            switchMap(() =>
                from(this.organizationDataService.fetchAllOrganizations()).pipe(
                    switchMap((organizations: Organization[]) => [ActionFetchAllOrganizationsSuccess({ organizations })]),
                    catchError((error) => [ActionFetchAllOrganizationsFailure({ error: error.message || error })])
                )
            )
        )
    );

    fetchOrganizationById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionFetchOrganizationByIdStart),
            map((action) => action.organizationId),
            filter((organizationId) => !!organizationId),
            switchMap((organizationId) =>
                from(this.organizationDataService.fetchOrganizationById(organizationId)).pipe(
                    switchMap((organization: Organization) => [ActionFetchOrganizationByIdSuccess({ organization })]),
                    catchError((error) => [ActionFetchOrganizationByIdFailure({ error: error.message || error })])
                )
            )
        )
    );

    createOrganization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionCreateOrganizationStart),
            map((action) => action.organization),
            filter((organization) => !!organization),
            switchMap((organization) =>
                from(this.organizationDataService.createOrganization(organization)).pipe(
                    switchMap((response: Organization) => [ActionCreateOrganizationSuccess({ organization: response })]),
                    catchError((error) => [ActionCreateOrganizationFailure({ error: error.message || error })])
                )
            )
        )
    );

    updateOrganization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionUpdateOrganizationStart),
            map((action) => ({ organizationId: action.organizationId, organization: action.organization })),
            filter(({ organizationId, organization }) => !!organizationId && !!organization),
            switchMap(({ organizationId, organization }) =>
                from(this.organizationDataService.updateOrganization(organizationId, organization)).pipe(
                    switchMap((response: Organization) => [ActionUpdateOrganizationSuccess({ organization: response })]),
                    catchError((error) => [ActionUpdateOrganizationFailure({ error: error.message || error })])
                )
            )
        )
    );

    deleteOrganization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionDeleteOrganizationByIdStart),
            map((action) => action.organizationId),
            filter((organizationId) => !!organizationId),
            switchMap((organizationId) =>
                from(this.organizationDataService.deleteOrganizationById(organizationId)).pipe(
                    switchMap((organization: Organization) => [ActionDeleteOrganizationByIdSuccess({ organization })]),
                    catchError((error) => [ActionDeleteOrganizationByIdFailure({ error: error.message || error })])
                )
            )
        )
    );
}
