import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthConstants, AuthState, UserDetails, UserTypes } from '../auth/store/auth.model';
import { LocalStorageModel } from './local-storage.model';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService extends LocalStorageModel {
    constructor() {
        super();
    }

    static loadAllState(): any {
        const APP_PREFIX = environment.appId;
        if (localStorage.getItem(`${APP_PREFIX}-${AuthConstants.identityId}`)) {
            const userDetails: UserDetails = {
                uid: localStorage.getItem(`${APP_PREFIX}-${AuthConstants.identityId}`),
                mobile: localStorage.getItem(`${APP_PREFIX}-${AuthConstants.mobile}`),
                email: localStorage.getItem(`${APP_PREFIX}-${AuthConstants.email}`),
                profile_pic: localStorage.getItem(`${APP_PREFIX}-${AuthConstants.profilePic}`),
                user_type: localStorage.getItem(`${APP_PREFIX}-${AuthConstants.userType}`) as UserTypes,
                last_seen: localStorage.getItem(`${APP_PREFIX}-${AuthConstants.loginTime}`),
            };
            const authState: AuthState = {
                user: userDetails,
                token: localStorage.getItem(`${APP_PREFIX}-${AuthConstants.accessToken}`),
                refreshToken: localStorage.getItem(`${APP_PREFIX}-${AuthConstants.refreshToken}`),
                isLoggedIn: true,
                isAuthenticating: false,
            };
            return {
                auth: authState,
                global: { isLoading: true },
            };
        } else {
            return null;
        }
    }

    getItem(key: string): string {
        if (localStorage.getItem(this.normalized(key)) !== 'undefined') {
            return localStorage.getItem(this.normalized(key));
        }
        return null;
    }

    setItem(key: string, value: string): void {
        localStorage.setItem(this.normalized(key), value);
    }

    removeItem(key: string): void {
        localStorage.removeItem(this.normalized(key));
    }

    clear(): void {
        Object.keys(localStorage)
            .filter((key) => key.includes(environment.appId))
            .forEach((item) => localStorage.removeItem(item));
    }

    private normalized(key: string): string {
        return `${environment.appId}-${key}`;
    }
}
