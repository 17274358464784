import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { MetaReducer, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { RouterStateUrl } from './router/router.state';
import { environment } from 'src/environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { debug } from './meta-reducers/debug.reducer';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage';
import { clearState } from './meta-reducers/clear-state.reducer';

export const metaReducers: MetaReducer<AppState>[] = [
    initStateFromLocalStorage,
    clearState
];

if (!environment.production) {
    metaReducers.unshift(storeFreeze);
    metaReducers.unshift(debug);
}

export const reducers: ActionReducerMap<AppState> = {
    router: routerReducer,
};

export const selectRouterState = createFeatureSelector<AppState, RouterReducerState<RouterStateUrl>>('router');

export interface AppState {
    router: RouterReducerState<RouterStateUrl>;
}
