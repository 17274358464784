import { Action, createReducer, on } from '@ngrx/store';
import {
    ActionIsAuthenticating,
    ActionLoginWithEmailFailure,
    ActionLoginWithEmailSuccess,
    ActionSetAuthError,
    ActionSetAuthToken,
    ActionSetIsButtonsDiabled,
    ActionSetIsEmailVerified,
    ActionSetIsLoggedIn,
    ActionSetRedirectUrl,
    ActionSetRefreshToken,
} from './auth.actions';
import { AuthState } from './auth.model';

const initialState: AuthState = {
    user: null,
    token: null,
    refreshToken: null,
    isLoggedIn: false,
};

const reducer = createReducer(
    initialState,
    on(ActionLoginWithEmailSuccess, (state, { userDetails }) => ({
        ...state,
        user: userDetails,
        isAuthenticating: false,
    })),
    on(ActionLoginWithEmailFailure, (state, {}) => ({ ...state, isAuthenticating: undefined, isLoggedIn: undefined })),
    on(ActionIsAuthenticating, (state, { isAuthenticating }) => ({ ...state, isAuthenticating })),
    on(ActionSetAuthToken, (state, { token }) => ({ ...state, token })),
    on(ActionSetRefreshToken, (state, { refreshToken }) => ({ ...state, refreshToken })),
    on(ActionSetAuthError, (state, { authError }) => ({ ...state, authError })),
    on(ActionSetIsLoggedIn, (state, { isLoggedIn }) => ({ ...state, isLoggedIn })),
    on(ActionSetIsButtonsDiabled, (state, { isButtonsDisabled }) => ({ ...state, isButtonsDisabled })),
    on(ActionSetIsEmailVerified, (state, { isEmailVerified }) => ({ ...state, isEmailVerified })),
    on(ActionSetRedirectUrl, (state, { redirectUrl }) => ({ ...state, redirectUrl }))
);

export function authReducer(state: AuthState, action: Action): AuthState {
    return reducer(state, action);
}
