<div class="page-header-container">
    <div class="left-container">
        <nz-breadcrumb [nzAutoGenerate]="true"></nz-breadcrumb>
        <span class="heading">{{ title }}</span>
    </div>
    <div class="right-container">
        <button *ngFor="let button of buttons" nz-button [nzType]="button.type" [routerLink]="[button.routerLink]">
            <i nz-icon [nzType]="button.icon" *ngIf="button.icon"></i>
            {{ button.name }}
        </button>
    </div>
</div>
