import { Action, createReducer, on } from '@ngrx/store';
import {
    ActionFetchCitiesByStateIdFailure,
    ActionFetchCitiesByStateIdSuccess,
    ActionFetchCitiesFailure,
    ActionFetchCitiesSuccess,
    ActionFetchStatesFailure,
    ActionFetchStatesSuccess,
    ActionSetIsRegionLoading,
} from './region.actions';
import { RegionState } from './region.model';
import * as orderBy from 'lodash.orderby';

const initialState: RegionState = {
    states: null,
    cities: null,
    fetchError: null
};

const reducer = createReducer(
    initialState,
    on(ActionFetchStatesSuccess, (state, { states }) => ({ ...state, states: orderBy(states, ['name'], ['asc']), isRegionLoading: false })),
    on(ActionFetchStatesFailure, (state, { error }) => ({ ...state, fetchError: error, isRegionLoading: undefined })),
    on(ActionFetchCitiesSuccess, (state, { cities }) => ({ ...state, cities, isRegionLoading: false })),
    on(ActionFetchCitiesFailure, (state, { error }) => ({ ...state, fetchError: error, isRegionLoading: undefined })),
    on(ActionFetchCitiesByStateIdSuccess, (state, { cities }) => ({ ...state, cities, isRegionLoading: false })),
    on(ActionFetchCitiesByStateIdFailure, (state, { error }) => ({ ...state, fetchError: error, isRegionLoading: undefined })),
    on(ActionSetIsRegionLoading, (state, { isRegionLoading }) => ({ ...state, isRegionLoading }))
);

export function regionReducer(state: RegionState, action: Action): RegionState {
    return reducer(state, action);
}
