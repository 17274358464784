import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActionFetchCitiesByStateIdStart, ActionFetchCitiesStart, ActionFetchStatesStart } from './store/region.actions';
import { State } from './store/region.model';
import { selectCities, selectFetchError, selectIsRegionLoading, selectStates } from './store/region.selectors';

@Injectable({
    providedIn: 'root',
})
export class RegionService {
    constructor(private readonly store$: Store<State>) {}

    isRegionLoading$ = this.store$.pipe(select(selectIsRegionLoading));
    getFetchError$ = this.store$.pipe(select(selectFetchError));
    getStates$ = this.store$.pipe(select(selectStates));
    getCities$ = this.store$.pipe(select(selectCities));

    fetchStates(): void {
        this.store$.dispatch(ActionFetchStatesStart());
    }

    fetchCities(): void {
        this.store$.dispatch(ActionFetchCitiesStart());
    }

    fetchCitiesByStateId(stateId: number): void {
        this.store$.dispatch(ActionFetchCitiesByStateIdStart({ stateId }));
    }
}
