import { createAction, props } from '@ngrx/store';
import { UserDetails, UserTypes } from './auth.model';

export enum AuthActionsTypes {
    SetUserDetails = '[Auth] Set User Details',
    UpdateUserDetails = '[Auth] Update User Details',
    SetRedirectUrl = '[Auth] Set Redirect Url',
    SetAuthToken = '[Auth] Set Auth Token',
    SetRefreshToken = '[Auth] Set Refresh Token',
    SetAuthError = '[Auth] Set Auth Error',
    SetIsAuthenticating = '[Auth] Set Is Authenticating',
    SetIsLoggedIn = '[Auth] Set Is Logged In',
    SetIsButtonsDisabled = '[Auth] Set Is Buttons Disabled',
    RenewAccessToken = '[Auth] Renew Access Token',
    LoginWithEmailStart = '[Auth] Login with Email Start',
    LoginWithEmailSuccess = '[Auth] Login with Email Success',
    LoginWithEmailFailure = '[Auth] Login with Email Failure',
    ResetPasswordStart = '[Auth] Reset Password Start',
    ResetPasswordSuccess = '[Auth] Reset Password Success',
    ResetPasswordFailure = '[Auth] Reset Password Failure',
    UpdatePasswordStart = '[Auth] Update Password Start',
    UpdatePasswordSuccess = '[Auth] Update Password Success',
    UpdatePasswordFailure = '[Auth] Update Password Failure',
    LogoutStart = '[Auth] Logout Start',
    LogoutSuccess = '[Auth] Logout Success',
    LogoutFailure = '[Auth] Logout Failure',
    SendEmailVerificationOTPStart = '[Auth] Send Email Verification OTP Start',
    SendEmailVerificationOTPSuccess = '[Auth] Send Email Verification OTP Success',
    SendEmailVerificationOTPFailure = '[Auth] Send Email Verification OTP Failure',
    VerifyEmailOTPStart = '[Auth] Verify Email OTP Start',
    VerifyEmailOTPSuccess = '[Auth] Verify Email OTP Success',
    VerifyEmailOTPFailure = '[Auth] Verify Email OTP Failure',
    SetIsEmailVerified = '[Auth] Set Is Email Verified'
}

export const ActionSetUserDetails = createAction(
    AuthActionsTypes.SetUserDetails,
    props<{ userDetails: UserDetails }>()
);

export const ActionUpdateUserDetails = createAction(
    AuthActionsTypes.UpdateUserDetails,
    props<{ userDetails: UserDetails }>()
);

export const ActionSetRedirectUrl = createAction(
    AuthActionsTypes.SetRedirectUrl,
    props<{ redirectUrl: string }>()
);

export const ActionSetAuthToken = createAction(
    AuthActionsTypes.SetAuthToken,
    props<{ token: string }>()
);

export const ActionSetAuthError = createAction(
    AuthActionsTypes.SetAuthError,
    props<{ authError: string }>()
);

export const ActionIsAuthenticating = createAction(
    AuthActionsTypes.SetIsAuthenticating,
    props<{ isAuthenticating: boolean }>()
);

export const ActionSetIsLoggedIn = createAction(
    AuthActionsTypes.SetIsLoggedIn,
    props<{ isLoggedIn: boolean }>()
);

export const ActionSetIsButtonsDiabled = createAction(
    AuthActionsTypes.SetIsButtonsDisabled,
    props<{ isButtonsDisabled: boolean }>()
);

export const ActionRenewAccessToken = createAction(
    AuthActionsTypes.RenewAccessToken
);

export const ActionLoginWithEmailStart = createAction(
    AuthActionsTypes.LoginWithEmailStart,
    props<{ email: string, password: string }>()
);

export const ActionLoginWithEmailSuccess = createAction(
    AuthActionsTypes.LoginWithEmailSuccess,
    props<{ userDetails: UserDetails }>()
);

export const ActionLoginWithEmailFailure = createAction(
    AuthActionsTypes.LoginWithEmailFailure,
    props<{ error: any }>()
);

export const ActionResetPasswordStart = createAction(
    AuthActionsTypes.ResetPasswordStart,
    props<{ email: string }>()
);

export const ActionResetPasswordSuccess = createAction(
    AuthActionsTypes.ResetPasswordSuccess,
    props<{ status: boolean }>()
);

export const ActionResetPasswordFailure = createAction(
    AuthActionsTypes.ResetPasswordFailure,
    props<{ error: string }>()
);

export const ActionUpdatePasswordStart = createAction(
    AuthActionsTypes.UpdatePasswordStart,
    props<{ password: string, token: string }>()
);

export const ActionUpdatePasswordSuccess = createAction(
    AuthActionsTypes.UpdatePasswordSuccess,
    props<{ status: boolean }>()
);

export const ActionUpdatePasswordFailure = createAction(
    AuthActionsTypes.UpdatePasswordFailure,
    props<{ error: string }>()
);

export const ActionSetRefreshToken = createAction(
    AuthActionsTypes.SetRefreshToken,
    props<{ refreshToken: string }>()
);

export const ActionLogoutStart = createAction(
    AuthActionsTypes.LogoutStart
);

export const ActionLogoutSuccess = createAction(
    AuthActionsTypes.LogoutSuccess
);

export const ActionLogoutFailure = createAction(
    AuthActionsTypes.LogoutFailure
);

export const ActionSendEmailVerificationOTPStart = createAction(
    AuthActionsTypes.SendEmailVerificationOTPStart,
    props<{ email: string; }>()
);

export const ActionSendEmailVerificationOTPSuccess = createAction(
    AuthActionsTypes.SendEmailVerificationOTPSuccess
);

export const ActionSendEmailVerificationOTPFailure = createAction(
    AuthActionsTypes.SendEmailVerificationOTPFailure,
    props<{ error: string }>()
);

export const ActionVerifyEmailOTPStart = createAction(
    AuthActionsTypes.VerifyEmailOTPStart,
    props<{ email: string, otp: number }>()
);

export const ActionVerifyEmailOTPSuccess = createAction(
    AuthActionsTypes.VerifyEmailOTPSuccess
);

export const ActionVerifyEmailOTPFailure = createAction(
    AuthActionsTypes.VerifyEmailOTPFailure,
    props<{ error: string }>()
);

export const ActionSetIsEmailVerified = createAction(
    AuthActionsTypes.SetIsEmailVerified,
    props<{ isEmailVerified: boolean }>()
);
