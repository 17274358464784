import { createAction, props } from '@ngrx/store';
import { BusinessAdmin, BusinessAdminRequest } from './business-admin.model';

export enum BusinessAdminActionsTypes {
    FetchAllBusinessAdminStart = '[Business Admin] Fetch All Business Admin Start',
    FetchAllBusinessAdminSuccess = '[Business Admin] Fetch All Business Admin Success',
    FetchAllBusinessAdminFailure = '[Business Admin] Fetch All Business Admin Failure',
    FetchBusinessAdminByIdStart = '[Business Admin] Fetch Business Admin by Id Start',
    FetchBusinessAdminByIdSuccess = '[Business Admin] Fetch Business Admin by Id Success',
    FetchBusinessAdminByIdFailure = '[Business Admin] Fetch Business Admin by Id Failure',
    CreateBusinessAdminStart = '[Business Admin] Create Business Admin Start',
    CreateBusinessAdminSuccess = '[Business Admin] Create Business Admin Success',
    CreateBusinessAdminFailure = '[Business Admin] Create Business Admin Failure',
    UpdateBusinessAdminStart = '[Business Admin] Update Business Admin Start',
    UpdateBusinessAdminSuccess = '[Business Admin] Update Business Admin Success',
    UpdateBusinessAdminFailure = '[Business Admin] Update Business Admin Failure',
    DeleteBusinessAdminByIdStart = '[Business Admin] Delete Business Admin by Id Start',
    DeleteBusinessAdminByIdSuccess = '[Business Admin] Delete Business Admin by Id Success',
    DeleteBusinessAdminByIdFailure = '[Business Admin] Delete Business Admin by Id Failure',
    SetIsBusinessAdminLoading = '[Business Admin] Set Is Business Admin Loading',
}

export const ActionFetchAllBusinessAdminStart = createAction(
    BusinessAdminActionsTypes.FetchAllBusinessAdminStart
);

export const ActionFetchAllBusinessAdminSuccess = createAction(
    BusinessAdminActionsTypes.FetchAllBusinessAdminSuccess,
    props<{ businessAdmins: BusinessAdmin[] }>()
);

export const ActionFetchAllBusinessAdminFailure = createAction(
    BusinessAdminActionsTypes.FetchAllBusinessAdminFailure,
    props<{ error: string }>()
);

export const ActionFetchBusinessAdminByIdStart = createAction(
    BusinessAdminActionsTypes.FetchBusinessAdminByIdStart,
    props<{ businessAdminId: string }>()
);

export const ActionFetchBusinessAdminByIdSuccess = createAction(
    BusinessAdminActionsTypes.FetchBusinessAdminByIdSuccess,
    props<{ businessAdmin: BusinessAdmin }>()
);

export const ActionFetchBusinessAdminByIdFailure = createAction(
    BusinessAdminActionsTypes.FetchBusinessAdminByIdFailure,
    props<{ error: string }>()
);

export const ActionCreateBusinessAdminStart = createAction(
    BusinessAdminActionsTypes.CreateBusinessAdminStart,
    props<{ businessAdmin: BusinessAdminRequest }>()
);

export const ActionCreateBusinessAdminSuccess = createAction(
    BusinessAdminActionsTypes.CreateBusinessAdminSuccess,
    props<{ businessAdmin: BusinessAdmin }>()
);

export const ActionCreateBusinessAdminFailure = createAction(
    BusinessAdminActionsTypes.CreateBusinessAdminFailure,
    props<{ error: string }>()
);

export const ActionUpdateBusinessAdminStart = createAction(
    BusinessAdminActionsTypes.UpdateBusinessAdminStart,
    props<{ businessAdminId: string, businessAdmin: BusinessAdminRequest }>()
);

export const ActionUpdateBusinessAdminSuccess = createAction(
    BusinessAdminActionsTypes.UpdateBusinessAdminSuccess,
    props<{ businessAdmin: BusinessAdmin }>()
);

export const ActionUpdateBusinessAdminFailure = createAction(
    BusinessAdminActionsTypes.UpdateBusinessAdminFailure,
    props<{ error: string }>()
);

export const ActionDeleteBusinessAdminByIdStart = createAction(
    BusinessAdminActionsTypes.DeleteBusinessAdminByIdStart,
    props<{ businessAdminId: string }>()
);

export const ActionDeleteBusinessAdminByIdSuccess = createAction(
    BusinessAdminActionsTypes.DeleteBusinessAdminByIdSuccess,
    props<{ businessAdmin: BusinessAdmin }>()
);

export const ActionDeleteBusinessAdminByIdFailure = createAction(
    BusinessAdminActionsTypes.DeleteBusinessAdminByIdFailure,
    props<{ error: string }>()
);

export const ActionSetIsBusinessAdminLoading = createAction(
    BusinessAdminActionsTypes.SetIsBusinessAdminLoading,
    props<{ isBusinessAdminLoading: boolean }>()
);
