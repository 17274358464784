import { Action, ActionReducer } from '@ngrx/store';
import { AppState } from '../core.state';
import * as AuthActions from '../auth/store/auth.actions';

export function clearState(reducer): ActionReducer<AppState> {
    return (state, action: Action) => {
        if (action.type === AuthActions.ActionLogoutSuccess.type) {
            state = undefined;
        }
        return reducer(state, action);
    };
}
