import { createAction, props } from '@ngrx/store';
import { City, States } from './region.model';

export enum RegionActionsTypes {
    FetchStatesStart = '[Region] Fetch States Start',
    FetchStatesSuccess = '[Region] Fetch States Success',
    FetchStatesFailure = '[Region] Fetch States Failure',
    FetchCitiesStart = '[Region] Fetch Cities Start',
    FetchCitiesSuccess = '[Region] Fetch Cities Success',
    FetchCitiesFailure = '[Region] Fetch Cities Failure',
    FetchCitiesByStateIdStart = '[Region] Fetch Cities by State Id Start',
    FetchCitiesByStateIdSuccess = '[Region] Fetch Cities by State Id Success',
    FetchCitiesByStateIdFailure = '[Region] Fetch Cities by State Id Failure',
    SetIsRegionLoading = '[Region] Set Is Region Loading'
}

export const ActionFetchStatesStart = createAction(
    RegionActionsTypes.FetchStatesStart,
);

export const ActionFetchStatesSuccess = createAction(
    RegionActionsTypes.FetchStatesSuccess,
    props<{ states: States[] }>()
);

export const ActionFetchStatesFailure = createAction(
    RegionActionsTypes.FetchStatesFailure,
    props<{ error: string }>()
);

export const ActionFetchCitiesStart = createAction(
    RegionActionsTypes.FetchCitiesStart,
);

export const ActionFetchCitiesSuccess = createAction(
    RegionActionsTypes.FetchCitiesSuccess,
    props<{ cities: City[] }>()
);

export const ActionFetchCitiesFailure = createAction(
    RegionActionsTypes.FetchCitiesFailure,
    props<{ error: string }>()
);

export const ActionFetchCitiesByStateIdStart = createAction(
    RegionActionsTypes.FetchCitiesByStateIdStart,
    props<{ stateId: number }>()
);

export const ActionFetchCitiesByStateIdSuccess = createAction(
    RegionActionsTypes.FetchCitiesByStateIdSuccess,
    props<{ cities: City[] }>()
);

export const ActionFetchCitiesByStateIdFailure = createAction(
    RegionActionsTypes.FetchCitiesByStateIdFailure,
    props<{ error: string }>()
);

export const ActionSetIsRegionLoading = createAction(
    RegionActionsTypes.SetIsRegionLoading,
    props<{ isRegionLoading: boolean }>()
);
