import { Injectable } from '@angular/core';
import { HttpClientService } from '../http/http-client.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import { ResponseLogin, ResponseRefreshToken, UserTypes } from './store/auth.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthDataService {
    constructor(private readonly http: HttpClientService) {}

    async loginWithEmail(email: string, password: string): Promise<ResponseLogin> {
        try {
            return new Promise((resolve, reject) =>
                this.http.post(environment.api.login, { email, password }).subscribe((result: ResponseLogin) => resolve(result), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    refreshAccessToken(uid: string, refreshToken: string): Observable<ResponseRefreshToken> {
        return this.http.post(environment.api.refreshToken, { uid, refresh_token: refreshToken });
    }

    async forgotPassword(email: string): Promise<boolean> {
        try {
            return new Promise((resolve, reject) =>
                this.http.get(`${environment.api.forgotPassword}/email/${email}`).subscribe(() => resolve(true), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async resetPassword(password: string, token: string): Promise<boolean> {
        try {
            return new Promise((resolve, reject) =>
                this.http.post(environment.api.resetPassword, { password, token }).subscribe((_) => resolve(true), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    sendEmailVerificationOTP(email: string): Observable<{ status: boolean }> {
        return this.http.post(environment.api.OTPViaEmail, { email });
    }

    verifyEmailOTP(email: string, otp: number): Observable<{ status: boolean }> {
        const requestBody = {
            email,
            otp,
            user_type: UserTypes.ADMIN,
        };
        return this.http.post(environment.api.verfiyOTP, requestBody);
    }

    async logout(): Promise<void> {
        await firebase.auth().signOut();
    }
}
