import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActionSetIsLoading } from './store/global.actions';
import { State } from './store/global.model';
import { selectIsLoading } from './store/global.selectors';

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    constructor(private readonly store$: Store<State>) {}

    isLoading$ = this.store$.pipe(select(selectIsLoading));

    setIsLoading(isLoading: boolean): void {
        this.store$.dispatch(ActionSetIsLoading({ isLoading }));
    }
}
