import { Component, OnInit } from '@angular/core';

export enum CategoryModalActions {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
}

@Component({
    selector: 'app-modal-category',
    templateUrl: './modal-category.component.html',
    styleUrls: ['./modal-category.component.less'],
})
export class ModalCategoryComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
