import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { organizationReducer } from './store/organization.reducer';
import { StoreModule } from '@ngrx/store';
import { OrganizationEffects } from './store/organization.effects';
import { EffectsModule } from '@ngrx/effects';
import { OrganizationService } from './organization.service';
import { OrganizationDataService } from './organization.data.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('organizations', organizationReducer), EffectsModule.forFeature([OrganizationEffects])],
    providers: [OrganizationService, OrganizationDataService]
})
export class OrganizationModule {}
