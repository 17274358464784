import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RegionState, State } from './region.model';

export const selectRegionState = createFeatureSelector<State, RegionState>('region');

export const selectStates = createSelector(selectRegionState, (state) => state.states);

export const selectCities = createSelector(selectRegionState, (state) => state.cities);

export const selectFetchError = createSelector(selectRegionState, (state) => state.fetchError || null);

export const selectIsRegionLoading = createSelector(selectRegionState, (state) => state.isRegionLoading || false);
