import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import { NavigationComponent } from './components/navigation/navigation.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { LeadDrawerComponent } from './components/lead-drawer/lead-drawer.component';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ModalCategoryComponent } from './components/modal-category/modal-category.component';

@NgModule({
    declarations: [NavigationComponent, CopyrightComponent, LeadDrawerComponent, TruncatePipe, PageHeaderComponent, ModalCategoryComponent],
    imports: [
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        NgxChartsModule,
        NzIconModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzCheckboxModule,
        NzLayoutModule,
        NzMenuModule,
        NzToolTipModule,
        NzTypographyModule,
        NzDropDownModule,
        NzAvatarModule,
        NzBadgeModule,
        NzSelectModule,
        NzGridModule,
        NzDividerModule,
        NzTableModule,
        NzDrawerModule,
        NzDescriptionsModule,
        NzMessageModule,
        NzDatePickerModule,
        NzBreadCrumbModule,
        NzSpaceModule,
        NzTabsModule,
        NzStepsModule,
        NzSpinModule,
        NzModalModule,
        NzRadioModule,
        NzTransferModule,
        NzUploadModule
    ],
    exports: [
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        NzIconModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzCheckboxModule,
        NzLayoutModule,
        NzMenuModule,
        NzToolTipModule,
        NzTypographyModule,
        NzDropDownModule,
        NzAvatarModule,
        NzBadgeModule,
        NzSelectModule,
        NzGridModule,
        NzDividerModule,
        NzTableModule,
        NzDrawerModule,
        NzDescriptionsModule,
        NzMessageModule,
        NzDatePickerModule,
        NzBreadCrumbModule,
        NzSpaceModule,
        NzTabsModule,
        NzStepsModule,
        NzSpinModule,
        NzModalModule,
        NzRadioModule,
        NzTransferModule,
        NzUploadModule,
        NavigationComponent,
        CopyrightComponent,
        LeadDrawerComponent,
        PageHeaderComponent,
        TruncatePipe,
    ],
})
export class SharedModule {}
