export const commonConfig = {
    appId: 'on-demand_services',
    api: {
        login: '/login',
        refreshToken: '/refresh-token',
        forgotPassword: '/forgot-password',
        resetPassword: '/reset-password',
        OTPViaEmail: '/otp-via-email',
        verfiyOTP: '/verify-otp',
        states: '/states',
        cities: '/cities',
        organization: '/organization',
        businessAdmin: '/business-admin',
    },
};
