import { AppState } from '../../core.state';

export interface AuthState {
    user: UserDetails;
    token: string;
    refreshToken: string;
    isAuthenticating?: boolean;
    isLoggedIn: boolean;
    authError?: string;
    redirectUrl?: string;
    isButtonsDisabled?: boolean;
    isEmailVerified?: boolean;
}

export interface UserDetails {
    uid: string;
    mobile: string;
    email: string;
    profile_pic: string;
    user_type: UserTypes;
    last_seen: string;
}

export interface ResponseLogin {
    id: string;
    mobile: string;
    email: string;
    profile_pic: string;
    refresh_token: string;
    user_type: UserTypes;
    last_seen: string;
    access_token: string;
}
export interface ResponseRefreshToken {
    accessToken: string;
    updatedRefreshToken: string;
}

export enum UserTypes {
    BACK_OFFICE_STAFF = 'BACK_OFFICE_STAFF',
    BUSINESS_ADMIN = 'BUSINESS_ADMIN',
    ADMIN = 'ADMIN',
}

export const AuthConstants = {
    identityId: 'profile.identityId',
    mobile: 'profile.mobile',
    email: 'profile.email',
    profilePic: 'profile.profilePic',
    refreshToken: 'profile.refreshToken',
    userType: 'profile.userType',
    loginTime: 'profile.loginTime',
    accessToken: 'profile.accessToken',
    expireAt: 'profile.expireAt',
};

export const AUTH_SESSION_STORAGE = 'userProfile';

export interface State extends AppState {
    auth: AuthState;
}
