import { ActionReducer } from '@ngrx/store';

import { AppState } from '../core.state';

export function debug(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return function logReduceWrapper(state, action): AppState {
        const newState = reducer(state, action);
        console.log(`[DEBUG] action: ${action.type}`, {
            payload: action,
            oldState: state,
            newState,
        });
        return newState;
    };
}
