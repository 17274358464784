import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
    ActionCreateOrganizationFailure,
    ActionCreateOrganizationStart,
    ActionCreateOrganizationSuccess,
    ActionDeleteOrganizationByIdFailure,
    ActionDeleteOrganizationByIdStart,
    ActionDeleteOrganizationByIdSuccess,
    ActionFetchAllOrganizationsFailure,
    ActionFetchAllOrganizationsStart,
    ActionFetchAllOrganizationsSuccess,
    ActionFetchOrganizationByIdFailure,
    ActionFetchOrganizationByIdStart,
    ActionSetIsOrganizationLoading,
    ActionSetOrganizationForm,
    ActionUpdateOrganizationFailure,
    ActionUpdateOrganizationStart,
    ActionUpdateOrganizationSuccess,
} from './organization.actions';
import { Organization, OrganizationState } from './organization.model';

export const adapter: EntityAdapter<Organization> = createEntityAdapter<Organization>({
    selectId: (entity) => entity.id,
});

const initialState: OrganizationState = adapter.getInitialState({});

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

const reducer = createReducer(
    initialState,
    on(
        ActionFetchAllOrganizationsStart,
        ActionFetchOrganizationByIdStart,
        ActionCreateOrganizationStart,
        ActionUpdateOrganizationStart,
        ActionDeleteOrganizationByIdStart,
        (state) => ({ ...state, isOrganizationLoading: true })
    ),
    on(
        ActionFetchAllOrganizationsFailure,
        ActionFetchOrganizationByIdFailure,
        ActionCreateOrganizationFailure,
        ActionUpdateOrganizationFailure,
        ActionDeleteOrganizationByIdFailure,
        (state) => ({ ...state, isOrganizationLoading: false })
    ),
    on(
        ActionFetchAllOrganizationsSuccess,
        (state, { organizations }) => adapter.addMany(organizations, { ...state, isOrganizationLoading: false })
    ),
    on(
        ActionCreateOrganizationSuccess,
        (state, { organization }) => adapter.addOne(organization, { ...state, isOrganizationLoading: false })
    ),
    on(
        ActionUpdateOrganizationSuccess,
        (state, { organization }) => adapter.upsertOne(organization, { ...state, isOrganizationLoading: false })
    ),
    on(
        ActionDeleteOrganizationByIdSuccess,
        (state, { organization }) => adapter.removeOne(organization.id, { ...state, isOrganizationLoading: false })
    ),
    on(
        ActionSetIsOrganizationLoading,
        (state, { isOrganizationLoading }) => ({ ...state, isOrganizationLoading })
    ),
    on(
        ActionSetOrganizationForm,
        (state, { organizationForm }) => ({ ...state, organizationForm })
    ),
);

export function organizationReducer(state: OrganizationState, action: Action): OrganizationState {
    return reducer(state, action);
}
