import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../http/http-client.service';
import { Organization, OrganizationRequest } from './store/organization.model';

@Injectable({
    providedIn: 'root',
})
export class OrganizationDataService {
    constructor(private readonly http: HttpClientService) {}

    async fetchAllOrganizations(): Promise<Organization[]> {
        try {
            return new Promise((resolve, reject) =>
                this.http.get(environment.api.organization).subscribe((response: Organization[]) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async fetchOrganizationById(organizationId: string): Promise<Organization> {
        try {
            return new Promise((resolve, reject) =>
                this.http
                    .get(`${environment.api.organization}/${organizationId}`)
                    .subscribe((response: Organization) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async createOrganization(organization: OrganizationRequest): Promise<Organization> {
        try {
            return new Promise((resolve, reject) =>
                this.http
                    .post(environment.api.organization, organization)
                    .subscribe((response: Organization) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async updateOrganization(organizationId: string, organization: Partial<Organization>): Promise<Organization> {
        try {
            return new Promise((resolve, reject) =>
                this.http
                    .put(`${environment.api.organization}/${organizationId}`, organization)
                    .subscribe((response: Organization) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }

    async deleteOrganizationById(organizationId: string): Promise<Organization> {
        try {
            return new Promise((resolve, reject) =>
                this.http
                    .delete(`${environment.api.organization}/${organizationId}`)
                    .subscribe((response: Organization) => resolve(response), reject)
            );
        } catch (error) {
            throw new Error(error);
        }
    }
}
