import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionService } from './region.service';
import { RegionDataService } from './region.data.service';
import { StoreModule } from '@ngrx/store';
import { regionReducer } from './store/region.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RegionEffects } from './store/region.effects';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('region', regionReducer), EffectsModule.forFeature([RegionEffects])],
    providers: [RegionService, RegionDataService],
})
export class RegionModule {}
