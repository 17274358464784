import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-lead-drawer',
    templateUrl: './lead-drawer.component.html',
    styleUrls: ['./lead-drawer.component.less'],
})
export class LeadDrawerComponent implements OnInit {
    @Input() visible = false;

    constructor() {}

    ngOnInit(): void {}

    close(): void {
        this.visible = false;
    }
}
