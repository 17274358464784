import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalService } from './global.service';
import { StoreModule } from '@ngrx/store';
import { globalReducer } from './store/global.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('global', globalReducer)],
    providers: [GlobalService],
})
export class GlobalModule {}
