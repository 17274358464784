import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, AuthState } from './auth.model';

export const selectAuthState = createFeatureSelector<State, AuthState>('auth');

export const selectUserUid = createSelector(selectAuthState, (state) => state.user ? state.user.uid : null);

export const selectUserDetails = createSelector(selectAuthState, (state) => state.user);

export const selectAuthToken = createSelector(selectAuthState, (state) => state.token);

export const selectRefreshToken = createSelector(selectAuthState, (state) => state.refreshToken);

export const selectIsAuthenticating = createSelector(selectAuthState, (state) => state.isAuthenticating);

export const selectIsLoggedIn = createSelector(selectAuthState, (state) => state.isLoggedIn);

export const selectIsButtonsDiabled = createSelector(selectAuthState, (state) => state.isButtonsDisabled);

export const selectAuthError = createSelector(selectAuthState, (state) => state.authError);

export const selectRedirectUrl = createSelector(selectAuthState, (state) => state.redirectUrl);

export const selectIsEmailVerified = createSelector(selectAuthState, (state) => state.isEmailVerified);
