import { commonConfig } from './common';

export const environment = {
    ...commonConfig,
    appName: 'Dev:On-Demand Services',
    firebaseConfig: {
        apiKey: 'AIzaSyBPqmnvCZSmvSlp9ShqlBZbqer8GBv0FD8',
        authDomain: 'on-demand-services---develop.firebaseapp.com',
        databaseURL: 'https://on-demand-services---develop.firebaseio.com',
        projectId: 'on-demand-services---develop',
        storageBucket: 'on-demand-services---develop.appspot.com',
        messagingSenderId: '913325561394',
        appId: '1:913325561394:web:0f592ceeab2ee00b1e4e2b',
        measurementId: 'G-HB4V4CJ453',
    },
    backendUrl: 'https://dev-ondemand-services-api.shridhara.info',
    production: false,
};
