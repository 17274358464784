import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
    ActionCreateBusinessAdminFailure,
    ActionCreateBusinessAdminStart,
    ActionCreateBusinessAdminSuccess,
    ActionDeleteBusinessAdminByIdFailure,
    ActionDeleteBusinessAdminByIdStart,
    ActionDeleteBusinessAdminByIdSuccess,
    ActionFetchAllBusinessAdminFailure,
    ActionFetchAllBusinessAdminStart,
    ActionFetchAllBusinessAdminSuccess,
    ActionFetchBusinessAdminByIdFailure,
    ActionFetchBusinessAdminByIdStart,
    ActionFetchBusinessAdminByIdSuccess,
    ActionSetIsBusinessAdminLoading,
    ActionUpdateBusinessAdminFailure,
    ActionUpdateBusinessAdminStart,
    ActionUpdateBusinessAdminSuccess,
} from './business-admin.actions';
import { BusinessAdmin, BusinessAdminState } from './business-admin.model';

export const adapter: EntityAdapter<BusinessAdmin> = createEntityAdapter<BusinessAdmin>({
    selectId: (entity) => entity.id,
});

const initialState: BusinessAdminState = adapter.getInitialState({});

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

const reducer = createReducer(
    initialState,
    on(
        ActionFetchAllBusinessAdminStart,
        ActionFetchBusinessAdminByIdStart,
        ActionCreateBusinessAdminStart,
        ActionUpdateBusinessAdminStart,
        ActionDeleteBusinessAdminByIdStart,
        (state) => ({ ...state, isBusinessAdminLoading: true })
    ),
    on(
        ActionFetchAllBusinessAdminFailure,
        ActionFetchBusinessAdminByIdFailure,
        ActionCreateBusinessAdminFailure,
        ActionUpdateBusinessAdminFailure,
        ActionDeleteBusinessAdminByIdFailure,
        (state) => ({ ...state, isBusinessAdminLoading: false })
    ),
    on(
        ActionFetchAllBusinessAdminSuccess,
        (state, { businessAdmins }) => adapter.addMany(businessAdmins, { ...state, isBusinessAdminLoading: false })
    ),
    on(
        ActionFetchBusinessAdminByIdSuccess,
        (state, { businessAdmin }) => adapter.addOne(businessAdmin, { ...state, isBusinessAdminLoading: false })
    ),
    on(
        ActionCreateBusinessAdminSuccess,
        (state, { businessAdmin }) => adapter.addOne(businessAdmin, { ...state, isBusinessAdminLoading: false})
    ),
    on(
        ActionUpdateBusinessAdminSuccess,
        (state, { businessAdmin }) => adapter.upsertOne(businessAdmin, { ...state, isBusinessAdminLoading: false })
    ),
    on(
        ActionDeleteBusinessAdminByIdSuccess,
        (state, { businessAdmin }) => adapter.removeOne(businessAdmin.id, { ...state, isBusinessAdminLoading: false })
    ),
    on(
        ActionSetIsBusinessAdminLoading,
        (state, { isBusinessAdminLoading }) => ({ ...state, isBusinessAdminLoading })
    )
);

export function businessAdminReducer(state: BusinessAdminState, action: Action): BusinessAdminState {
    return reducer(state, action);
}
