import { createAction, props } from '@ngrx/store';
import { Organization, OrganizationForm, OrganizationRequest } from './organization.model';

export enum OrganizationActionsTypes {
    FetchAllOrganizationsStart = '[Organizations] Fetch All Organizations Start',
    FetchAllOrganizationsSuccess = '[Organizations] Fetch All Organizations Success',
    FetchAllOrganizationsFailure = '[Organizations] Fetch All Organizations Failure',
    FetchOrganizationByIdStart = '[Organizations] Fetch Organization by Id Start',
    FetchOrganizationByIdSuccess = '[Organizations] Fetch Organization by Id Success',
    FetchOrganizationByIdFailure = '[Organizations] Fetch Organization by Id Failure',
    CreateOrganizationStart = '[Organizations] Create Organization Start',
    CreateOrganizationSuccess = '[Organizations] Create Organization Success',
    CreateOrganizationFailure = '[Organizations] Create Organization Failure',
    UpdateOrganizationStart = '[Organizations] Update Organization Start',
    UpdateOrganizationSuccess = '[Organizations] Update Organization Success',
    UpdateOrganizationFailure = '[Organizations] Update Organization Failure',
    DeleteOrganizationByIdStart = '[Organizations] Delete Organization by Id Start',
    DeleteOrganizationByIdSuccess = '[Organizations] Delete Organization by Id Success',
    DeleteOrganizationByIdFailure = '[Organizations] Delete Organization by Id Failure',
    SetIsOrganizationLoading = '[Organizations] Set Is Organizations Loading',
    SetOrganizationForm = '[Organizations] Set Organization Form',
}

export const ActionFetchAllOrganizationsStart = createAction(
    OrganizationActionsTypes.FetchAllOrganizationsStart
);

export const ActionFetchAllOrganizationsSuccess = createAction(
    OrganizationActionsTypes.FetchAllOrganizationsSuccess,
    props<{ organizations: Organization[] }>()
);

export const ActionFetchAllOrganizationsFailure = createAction(
    OrganizationActionsTypes.FetchAllOrganizationsFailure,
    props<{ error: string }>()
);

export const ActionFetchOrganizationByIdStart = createAction(
    OrganizationActionsTypes.FetchOrganizationByIdStart,
    props<{ organizationId: string }>()
);

export const ActionFetchOrganizationByIdSuccess = createAction(
    OrganizationActionsTypes.FetchOrganizationByIdSuccess,
    props<{ organization: Organization }>()
);

export const ActionFetchOrganizationByIdFailure = createAction(
    OrganizationActionsTypes.FetchOrganizationByIdFailure,
    props<{ error: string }>()
);

export const ActionCreateOrganizationStart = createAction(
    OrganizationActionsTypes.CreateOrganizationStart,
    props<{ organization: OrganizationRequest }>()
);

export const ActionCreateOrganizationSuccess = createAction(
    OrganizationActionsTypes.CreateOrganizationSuccess,
    props<{ organization: Organization }>()
);

export const ActionCreateOrganizationFailure = createAction(
    OrganizationActionsTypes.CreateOrganizationFailure,
    props<{ error: string }>()
);

export const ActionUpdateOrganizationStart = createAction(
    OrganizationActionsTypes.UpdateOrganizationStart,
    props<{ organizationId: string, organization: Organization }>()
);

export const ActionUpdateOrganizationSuccess = createAction(
    OrganizationActionsTypes.UpdateOrganizationSuccess,
    props<{ organization: Organization }>()
);

export const ActionUpdateOrganizationFailure = createAction(
    OrganizationActionsTypes.UpdateOrganizationFailure,
    props<{ error: string }>()
);

export const ActionDeleteOrganizationByIdStart = createAction(
    OrganizationActionsTypes.DeleteOrganizationByIdStart,
    props<{ organizationId: string }>()
);

export const ActionDeleteOrganizationByIdSuccess = createAction(
    OrganizationActionsTypes.DeleteOrganizationByIdSuccess,
    props<{ organization: Organization }>()
);

export const ActionDeleteOrganizationByIdFailure = createAction(
    OrganizationActionsTypes.DeleteOrganizationByIdFailure,
    props<{ error: string }>()
);

export const ActionSetIsOrganizationLoading = createAction(
    OrganizationActionsTypes.SetIsOrganizationLoading,
    props<{ isOrganizationLoading: boolean }>()
);

export const ActionSetOrganizationForm = createAction(
    OrganizationActionsTypes.SetOrganizationForm,
    props<{ organizationForm: OrganizationRequest }>()
);
