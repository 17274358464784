import { Component, Input, OnInit } from '@angular/core';
import { navigation } from './navigation';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent implements OnInit {
    @Input() isCollapsed = false;
    navigation = navigation;

    constructor() {}

    ngOnInit(): void {}
}
