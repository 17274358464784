import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './core.state';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { environment } from 'src/environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import firebase from 'firebase/app';
import { AuthModule } from './auth/auth.module';
import { CustomSerializer } from './router/custom-serializer';
import { RegionModule } from './region/region.module';
import { GlobalModule } from './global/global.module';
import { CookieService } from 'ngx-cookie-service';
import { OrganizationModule } from './organization/organization.module';
import { BusinessAdminModule } from './business-admin/business-admin.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot([]),
        environment.production
            ? []
            : StoreDevtoolsModule.instrument({
                  name: environment.appId,
              }),
        GlobalModule,
        AuthModule,
        RegionModule,
        OrganizationModule,
        BusinessAdminModule,
    ],
    providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }, CookieService],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        } else {
            firebase.initializeApp(environment.firebaseConfig);
        }
    }
}
