import { Component, Input, OnInit } from '@angular/core';

export interface Button {
    name: string;
    routerLink: string;
    type: ButtonType;
    icon?: string;
}

export enum ButtonType {
    Primary = 'primary',
    Default = 'default',
}

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.less'],
})
export class PageHeaderComponent implements OnInit {
    @Input() title;
    @Input() buttons: Button[];

    constructor() {}

    ngOnInit(): void {}
}
