import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { Component } from '@angular/core';
import { GlobalService } from './core/global/global.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    animations: [
        trigger('loader', [
            transition(':enter', [animate('100ms', keyframes([style({ opacity: 0 }), style({ opacity: 1 })]))]),
            transition(':leave', [animate('1000ms', keyframes([style({ opacity: 1 }), style({ opacity: 0 })]))]),
        ]),
    ],
})
export class AppComponent {
    constructor(private readonly globalSerivce: GlobalService) {}

    isLoading$ = this.globalSerivce.isLoading$;
}
