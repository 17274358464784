import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, delay, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../core.state';
import { AuthDataService } from '../auth.data.service';
import {
    ActionIsAuthenticating,
    ActionLoginWithEmailFailure,
    ActionLoginWithEmailStart,
    ActionLoginWithEmailSuccess,
    ActionLogoutStart,
    ActionLogoutSuccess,
    ActionRenewAccessToken,
    ActionResetPasswordStart,
    ActionResetPasswordSuccess,
    ActionSendEmailVerificationOTPFailure,
    ActionSendEmailVerificationOTPStart,
    ActionSendEmailVerificationOTPSuccess,
    ActionSetAuthError,
    ActionSetAuthToken,
    ActionSetIsButtonsDiabled,
    ActionSetIsEmailVerified,
    ActionSetIsLoggedIn,
    ActionSetRedirectUrl,
    ActionSetRefreshToken,
    ActionUpdatePasswordStart,
    ActionUpdatePasswordSuccess,
    ActionVerifyEmailOTPFailure,
    ActionVerifyEmailOTPStart,
    ActionVerifyEmailOTPSuccess,
} from './auth.actions';
import { selectRedirectUrl, selectRefreshToken, selectUserUid } from './auth.selectors';
import * as dayjs from 'dayjs';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { AuthConstants } from './auth.model';

@Injectable({
    providedIn: 'root',
})
export class AuthEffects {
    constructor(
        private readonly actions$: Actions<Action>,
        private readonly store$: Store<AppState>,
        private readonly authDataService: AuthDataService,
        private readonly router: Router,
        private readonly localStorageService: LocalStorageService
    ) {}

    loginWithEmailStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionLoginWithEmailStart),
            filter(({ email, password }) => !!email && !!password),
            withLatestFrom(this.store$.pipe(select(selectRedirectUrl))),
            tap(() => this.store$.dispatch(ActionSetIsButtonsDiabled({ isButtonsDisabled: true }))),
            tap(() => this.store$.dispatch(ActionIsAuthenticating({ isAuthenticating: true }))),
            switchMap(([{ email, password }, redirectUrl]) =>
                from(this.authDataService.loginWithEmail(email, password)).pipe(
                    switchMap((userDetails) => {
                        const user = {
                            uid: userDetails.id,
                            mobile: userDetails.mobile,
                            email: userDetails.email,
                            profile_pic: userDetails.profile_pic,
                            user_type: userDetails.user_type,
                            last_seen: dayjs().format(), // TO DO: Remove after server time zone update
                        };
                        this.localStorageService.setItems({
                            [AuthConstants.identityId]: user.uid,
                            [AuthConstants.mobile]: user.mobile,
                            [AuthConstants.email]: user.email,
                            [AuthConstants.profilePic]: user.profile_pic,
                            [AuthConstants.userType]: user.user_type,
                            [AuthConstants.loginTime]: user.last_seen,
                            [AuthConstants.expireAt]: dayjs(user.last_seen).add(55, 'minute').format(),
                            [AuthConstants.accessToken]: userDetails.access_token,
                            [AuthConstants.refreshToken]: userDetails.refresh_token,
                        });
                        if (redirectUrl) {
                            this.router.navigate([redirectUrl]);
                        } else {
                            this.router.navigate(['/home/dashboard']);
                        }
                        return [
                            ActionLoginWithEmailSuccess({ userDetails: user }),
                            ActionSetAuthToken({ token: userDetails.access_token }),
                            ActionSetRefreshToken({ refreshToken: userDetails.refresh_token }),
                            ActionSetIsLoggedIn({ isLoggedIn: true }),
                            ActionSetIsButtonsDiabled({ isButtonsDisabled: false }),
                            ActionSetRedirectUrl({ redirectUrl: null }),
                        ];
                    }),
                    catchError((error) => [
                        ActionSetAuthError({ authError: error.message || error }),
                        ActionLoginWithEmailFailure({ error }),
                        ActionSetIsButtonsDiabled({ isButtonsDisabled: false }),
                        ActionIsAuthenticating({ isAuthenticating: false }),
                    ])
                )
            )
        )
    );

    // refreshAccessToken$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(ActionSetAuthToken),
    //         filter(() => {
    //             const date = new Date(this.localStorageService.getItem(AuthConstants.expireAt))
    //             console.log('Date', date);
    //             return true;
    //         }),
    //         delay(new Date(this.localStorageService.getItem(AuthConstants.expireAt))),
    //         withLatestFrom(this.store$.pipe(select(selectUserUid)), this.store$.pipe(select(selectRefreshToken))),
    //         filter(([_, uid, refreshToken]) => !!uid && !!refreshToken),
    //         switchMap(([_, uid, refreshToken]) =>
    //             from(this.authDataService.refreshAccessToken(uid, refreshToken)).pipe(
    //                 switchMap((newToken) => {
    //                     console.log('first');
    //                     this.localStorageService.setItems({
    //                         [AuthConstants.expireAt]: dayjs().add(55, 'minute').format(),
    //                         [AuthConstants.accessToken]: newToken.accessToken,
    //                         [AuthConstants.refreshToken]: newToken.updatedRefreshToken,
    //                     });
    //                     return [
    //                         ActionSetRefreshToken({ refreshToken: newToken.updatedRefreshToken }),
    //                         ActionSetAuthToken({ token: newToken.accessToken }),
    //                     ];
    //                 }),
    //                 catchError((error) => [ActionSetAuthError({ authError: error.message || error }), ActionLogoutStart()])
    //             )
    //         ),
    //         // first()
    //     )
    // );

    renewAccessToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionRenewAccessToken),
            withLatestFrom(
                this.store$.pipe(select(selectUserUid)),
                this.store$.pipe(select(selectRefreshToken)),
                this.store$.pipe(select(selectRedirectUrl))
            ),
            switchMap(([_, uid, refreshToken, redirectUrl]) => {
                if (!!uid && !!refreshToken) {
                    return this.authDataService.refreshAccessToken(uid, refreshToken).pipe(
                        switchMap((newToken) => {
                            this.localStorageService.setItems({
                                [AuthConstants.expireAt]: dayjs().add(55, 'minute').format(),
                                [AuthConstants.accessToken]: newToken.accessToken,
                                [AuthConstants.refreshToken]: newToken.updatedRefreshToken,
                            });
                            this.router.navigate([redirectUrl]);
                            return [
                                ActionSetRefreshToken({ refreshToken: newToken.updatedRefreshToken }),
                                ActionSetAuthToken({ token: newToken.accessToken }),
                            ];
                        }),
                        catchError((error) => [ActionSetAuthError({ authError: error.message || error }), ActionLogoutStart()])
                    );
                } else {
                    // TO DO: Handle condition for redirect when direct link is hit without token
                    return [ActionLogoutStart()];
                }
            })
            // first()
        )
    );

    forgotPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionResetPasswordStart),
            filter(({ email }) => !!email),
            tap(() => this.store$.dispatch(ActionSetIsButtonsDiabled({ isButtonsDisabled: true }))),
            tap(() => this.store$.dispatch(ActionIsAuthenticating({ isAuthenticating: true }))),
            switchMap(({ email }) =>
                from(this.authDataService.forgotPassword(email)).pipe(
                    switchMap((status) => {
                        this.router.navigate(['/auth/login']);
                        return [
                            ActionResetPasswordSuccess({ status }),
                            ActionSetIsLoggedIn({ isLoggedIn: false }),
                            ActionSetIsButtonsDiabled({ isButtonsDisabled: false }),
                            ActionIsAuthenticating({ isAuthenticating: false }),
                        ];
                    }),
                    catchError((error) => [
                        ActionSetAuthError({ authError: error.message || error }),
                        ActionSetIsButtonsDiabled({ isButtonsDisabled: false }),
                        ActionIsAuthenticating({ isAuthenticating: false }),
                    ])
                )
            )
        )
    );

    updatePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionUpdatePasswordStart),
            filter(({ password, token }) => !!password && !!token),
            tap(() => this.store$.dispatch(ActionSetIsButtonsDiabled({ isButtonsDisabled: true }))),
            tap(() => this.store$.dispatch(ActionIsAuthenticating({ isAuthenticating: true }))),
            switchMap(({ password, token }) =>
                from(this.authDataService.resetPassword(password, token)).pipe(
                    switchMap((status) => {
                        this.router.navigate(['/auth/login']);
                        return [
                            ActionUpdatePasswordSuccess({ status }),
                            ActionSetIsLoggedIn({ isLoggedIn: false }),
                            ActionSetIsButtonsDiabled({ isButtonsDisabled: false }),
                            ActionIsAuthenticating({ isAuthenticating: false }),
                        ];
                    }),
                    catchError((error) => [
                        ActionSetAuthError({ authError: error.message || error }),
                        ActionSetIsButtonsDiabled({ isButtonsDisabled: false }),
                        ActionIsAuthenticating({ isAuthenticating: false }),
                    ])
                )
            )
        )
    );

    sendEmailVerificationOTP$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionSendEmailVerificationOTPStart),
            filter(({ email }) => !!email),
            tap(() => this.store$.dispatch(ActionIsAuthenticating({ isAuthenticating: true }))),
            switchMap(({ email }) =>
                this.authDataService.sendEmailVerificationOTP(email).pipe(
                    switchMap(() => [ActionSendEmailVerificationOTPSuccess(), ActionIsAuthenticating({ isAuthenticating: false })]),
                    catchError((error) => [
                        ActionSendEmailVerificationOTPFailure({ error: error.message || error }),
                        ActionIsAuthenticating({ isAuthenticating: false }),
                    ])
                )
            )
        )
    );

    verifyEmailOTP$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionVerifyEmailOTPStart),
            filter(({ email, otp }) => !!email && !!otp),
            tap(() => this.store$.dispatch(ActionIsAuthenticating({ isAuthenticating: true }))),
            switchMap(({ email, otp }) =>
                this.authDataService.verifyEmailOTP(email, otp).pipe(
                    switchMap(() => [
                        ActionVerifyEmailOTPSuccess(),
                        ActionSetIsEmailVerified({ isEmailVerified: true }),
                        ActionIsAuthenticating({ isAuthenticating: false }),
                    ]),
                    catchError((error) => [
                        ActionVerifyEmailOTPFailure({ error: error.message || error }),
                        ActionSetIsEmailVerified({ isEmailVerified: false }),
                        ActionIsAuthenticating({ isAuthenticating: false }),
                    ])
                )
            )
        )
    );

    logoutStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionLogoutStart),
            switchMap(() =>
                from(this.authDataService.logout()).pipe(
                    map(() => {
                        this.router.navigate(['/auth/login']);
                        return ActionLogoutSuccess();
                    })
                )
            )
        )
    );
}
