import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OrganizationService } from '../../organization/organization.service';
import { ActionCreateOrganizationStart } from '../../organization/store/organization.actions';
import { selectOrganizationForm } from '../../organization/store/organization.selectors';
import { BusinessAdminDataService } from '../business-admin.data.service';
import {
    ActionCreateBusinessAdminFailure,
    ActionCreateBusinessAdminStart,
    ActionCreateBusinessAdminSuccess,
    ActionDeleteBusinessAdminByIdFailure,
    ActionDeleteBusinessAdminByIdStart,
    ActionDeleteBusinessAdminByIdSuccess,
    ActionFetchAllBusinessAdminFailure,
    ActionFetchAllBusinessAdminStart,
    ActionFetchAllBusinessAdminSuccess,
    ActionFetchBusinessAdminByIdFailure,
    ActionFetchBusinessAdminByIdStart,
    ActionFetchBusinessAdminByIdSuccess,
    ActionUpdateBusinessAdminFailure,
    ActionUpdateBusinessAdminStart,
    ActionUpdateBusinessAdminSuccess,
} from './business-admin.actions';
import { BusinessAdmin } from './business-admin.model';

@Injectable({
    providedIn: 'root',
})
export class BusinessAdminEffects {
    constructor(
        private readonly actions$: Actions<Action>,
        private readonly businessAdminDataService: BusinessAdminDataService,
        private readonly organizationService: OrganizationService
    ) {}

    fetchAllBusinessAdmins$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionFetchAllBusinessAdminStart),
            switchMap(() =>
                from(this.businessAdminDataService.fetchAllBusinessAdmins()).pipe(
                    switchMap((businessAdmins: BusinessAdmin[]) => [ActionFetchAllBusinessAdminSuccess({ businessAdmins })]),
                    catchError((error) => [ActionFetchAllBusinessAdminFailure({ error: error.message || error })])
                )
            )
        )
    );

    fetchBusinessAdminById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionFetchBusinessAdminByIdStart),
            map((action) => action.businessAdminId),
            filter((businessAdminId) => !!businessAdminId),
            switchMap((businessAdminId) =>
                from(this.businessAdminDataService.fetchBusinessAdminById(businessAdminId)).pipe(
                    switchMap((businessAdmin: BusinessAdmin) => [ActionFetchBusinessAdminByIdSuccess({ businessAdmin })]),
                    catchError((error) => [ActionFetchBusinessAdminByIdFailure({ error: error.message || error })])
                )
            )
        )
    );

    createBusinessAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionCreateBusinessAdminStart),
            map((action) => action.businessAdmin),
            filter((businessAdmin) => !!businessAdmin),
            withLatestFrom(this.organizationService.organizationForm$),
            switchMap(([businessAdmin, organizationForm]) =>
                from(this.businessAdminDataService.createBusinessAdmin(businessAdmin)).pipe(
                    switchMap((response: BusinessAdmin) => {
                        console.log('response', response);
                        const businessAdminId = response.id;
                        const organization = Object.assign({}, organizationForm);
                        organization.business_admin_id = businessAdminId;
                        return [
                            ActionCreateBusinessAdminSuccess({ businessAdmin: response }),
                            ActionCreateOrganizationStart({ organization }),
                        ];
                    }),
                    catchError((error) => [ActionCreateBusinessAdminFailure({ error: error.message || error })])
                )
            )
        )
    );

    updateBusinessAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionUpdateBusinessAdminStart),
            map((action) => ({ businessAdminId: action.businessAdminId, businessAdmin: action.businessAdmin })),
            filter(({ businessAdminId, businessAdmin }) => !!businessAdminId && !!businessAdmin),
            switchMap(({ businessAdminId, businessAdmin }) =>
                from(this.businessAdminDataService.updateBusinessAdmin(businessAdminId, businessAdmin)).pipe(
                    switchMap((response: BusinessAdmin) => [ActionUpdateBusinessAdminSuccess({ businessAdmin: response })]),
                    catchError((error) => [ActionUpdateBusinessAdminFailure({ error: error.message || error })])
                )
            )
        )
    );

    deleteBusinessAdminById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionDeleteBusinessAdminByIdStart),
            map((action) => action.businessAdminId),
            filter((businessAdminId) => !!businessAdminId),
            switchMap((businessAdminId) =>
                from(this.businessAdminDataService.deleteBusinessAdminById(businessAdminId)).pipe(
                    switchMap((businessAdmin: BusinessAdmin) => [ActionDeleteBusinessAdminByIdSuccess({ businessAdmin })]),
                    catchError((error) => [ActionDeleteBusinessAdminByIdFailure({ error: error.message || error })])
                )
            )
        )
    );
}
