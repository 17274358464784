import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../core.state';
import { RegionDataService } from '../region.data.service';
import {
    ActionFetchCitiesByStateIdFailure,
    ActionFetchCitiesByStateIdStart,
    ActionFetchCitiesByStateIdSuccess,
    ActionFetchCitiesFailure,
    ActionFetchCitiesStart,
    ActionFetchCitiesSuccess,
    ActionFetchStatesFailure,
    ActionFetchStatesStart,
    ActionFetchStatesSuccess,
    ActionSetIsRegionLoading,
} from './region.actions';
import { selectCities, selectStates } from './region.selectors';

@Injectable({
    providedIn: 'root',
})
export class RegionEffects {
    constructor(
        private readonly actions$: Actions<Action>,
        private readonly store$: Store<AppState>,
        private readonly regionDataService: RegionDataService
    ) {}

    fetchStates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionFetchStatesStart),
            withLatestFrom(this.store$.pipe(select(selectStates))),
            tap(() => this.store$.dispatch(ActionSetIsRegionLoading({ isRegionLoading: true }))),
            switchMap(([_, statesFromStore]) => {
                if (statesFromStore) {
                    return [ActionFetchStatesSuccess({ states: statesFromStore })];
                } else {
                    return from(this.regionDataService.fetchStates()).pipe(
                        switchMap((states) => [ActionFetchStatesSuccess({ states })]),
                        catchError((error) => [ActionFetchStatesFailure({ error: error.message || error })])
                    );
                }
            })
        )
    );

    fetchCities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionFetchCitiesStart),
            withLatestFrom(this.store$.pipe(select(selectCities))),
            tap(() => this.store$.dispatch(ActionSetIsRegionLoading({ isRegionLoading: true }))),
            switchMap(([_, citiesFromStore]) => {
                if (citiesFromStore) {
                    return [ActionFetchCitiesSuccess({ cities: citiesFromStore })];
                } else {
                    return from(this.regionDataService.fetchCities()).pipe(
                        switchMap((cities) => [ActionFetchCitiesSuccess({ cities })]),
                        catchError((error) => [ActionFetchCitiesFailure({ error: error.message || error })])
                    );
                }
            })
        )
    );

    fetchCitiesByStateId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionFetchCitiesByStateIdStart),
            filter(({ stateId }) => !!stateId),
            tap(() => this.store$.dispatch(ActionSetIsRegionLoading({ isRegionLoading: true }))),
            switchMap(({ stateId }) =>
                from(this.regionDataService.fetchCitiesByStateId(stateId)).pipe(
                    switchMap((cities) => [ActionFetchCitiesByStateIdSuccess({ cities })]),
                    catchError((error) => [ActionFetchCitiesByStateIdFailure({ error: error.message || error })])
                )
            )
        )
    );
}
