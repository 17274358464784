import { createAction, props } from '@ngrx/store';

export enum GlobalActionsTypes {
    SetIsLoading = '[Global] Set Is Loading',
}

export const ActionSetIsLoading = createAction(
    GlobalActionsTypes.SetIsLoading,
    props<{ isLoading: boolean }>()
);
