export interface Dictionary<T> {
    [index: string]: T;
}

export abstract class LocalStorageModel {
    abstract getItem(key: string): string;
    abstract setItem(key: string, value: string): void;
    abstract removeItem(key: string): void;
    abstract clear(): void;

    setItems(obj: Dictionary<string>): void {
        Object.keys(obj).forEach((key) => this.setItem(key, obj[key]));
    }
}
