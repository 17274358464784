<ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed" class="navigation">
    <ng-container *ngFor="let item of navigation">
        <ng-container *ngIf="item.link; else elseBlock">
            <li
                nz-menu-item
                nz-tooltip
                nzTooltipPlacement="right"
                nzMatchRouter
                [nzTooltipTitle]="isCollapsed ? item.title : ''"
                [routerLink]="item.link"
            >
                <i nz-icon [nzType]="item.icon"></i>
                <span>{{ item.title }}</span>
            </li>
        </ng-container>
        <ng-template #elseBlock>
            <li nz-submenu [nzTitle]="item.title" [nzIcon]="item.icon">
                <ul>
                    <ng-container *ngFor="let child of item.children">
                        <li nz-menu-item nzMatchRouter [routerLink]="child.link">
                            <span>{{ child.title }}</span>
                        </li>
                    </ng-container>
                </ul>
            </li>
        </ng-template>
    </ng-container>
</ul>
