import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationState, State } from './organization.model';
import { selectAll } from './organization.reducer';

export const selectOrganizationState = createFeatureSelector<State, OrganizationState>('organizations');

export const selectAllOrganizations = createSelector(selectOrganizationState, selectAll);

export const selectIsOrganizationLoading = createSelector(selectOrganizationState, (state) => state.isOrganizationLoading || false);

export const selectOrganizationForm = createSelector(selectOrganizationState, (state) => state.organizationForm);
