interface NavItem {
    title: string;
    icon: string;
    link?: string;
    children?: {
        title: string;
        link: string;
    }[];
}

export const navigation: NavItem[] = [
    {
        title: 'Dashboard',
        icon: 'dashboard',
        link: '/home/dashboard',
    },
    {
        title: 'Franchise Management',
        icon: 'book',
        link: '/home/franchise',
    },
    {
        title: 'Lead Management',
        icon: 'global',
        link: 'abc',
    },
    {
        title: 'Services Management',
        icon: 'tags',
        link: '/home/service',
    },
    // {
    //     title: 'Payment Management',
    //     icon: 'bank',
    //     children: [
    //         {
    //             title: 'Transactions',
    //             link: 'abc',
    //         },
    //         {
    //             title: 'Refunds',
    //             link: 'abc',
    //         },
    //         {
    //             title: 'Payouts',
    //             link: 'abc',
    //         },
    //     ],
    // },
    {
        title: 'Inventory',
        icon: 'shopping-cart',
        link: 'abc',
    },
    {
        title: 'Offer Management',
        icon: 'wallet',
        link: 'abc',
    },
    // {
    //     title: 'Campaigns',
    //     icon: 'wallet',
    //     children: [
    //         {
    //             title: 'Push Notification Campaign',
    //             link: 'abc',
    //         },
    //         {
    //             title: 'Email Compaign',
    //             link: 'abc',
    //         },
    //         {
    //             title: 'SMS Compaign',
    //             link: 'abc',
    //         },
    //     ],
    // },
    {
        title: 'Service Engineers',
        icon: 'solution',
        link: 'abc',
    },
    {
        title: 'Customers',
        icon: 'team',
        link: 'anc',
    },
    {
        title: 'Users Management',
        icon: 'team',
        link: 'anc',
    },
    // {
    //     title: 'Reports',
    //     icon: 'team',
    //     link: 'anc',
    // },
];
